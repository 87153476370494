import React, { useState } from 'react'
import { BannerImg, LogoIcon } from '../img/AllImages'
import { Col, Container, Row } from 'react-bootstrap'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import { Autoplay, Grid, Pagination } from 'swiper/modules';

// Swiper.use([Pagination, Grid]);

import { FeedBackSliderData, ProjectData, TeamCardData } from '../Pages/Data/Data';

const Loading = () => {
    return (
        <div className="loading">
            <img src={LogoIcon} alt="" />
        </div>
    )
}

const Banner = () => {
    return (
        <>
            <div className="banner">
                <Container>
                    <Row className="flex-column-reverse flex-lg-row flex-lg-wrap">
                        <Col md={10} className='mb-5 mb-lg-0'>
                            <div className="bannerContent" data-aos="fade-right">
                                <div className="bannerHanding">
                                    <h1>We <span> Transform </span> Your Vision Into Creative Results</h1>
                                </div>
                                <div className="bannerHandingMiddle ">
                                    <h4>PRECISE AND PROFICIENT</h4>
                                </div>
                                <div className="bannerPara ">
                                    <p>Dream it, <br /> believe it, <br /> build it your idea over experties</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={2} className='mb-5 mb-lg-0'>
                            <div className="bannerImg" data-aos="fade-left">
                                <img src={BannerImg} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const Heading = ({ HeadingText, ParaText }) => {
    return (
        <>
            <div className="heading">
                <h2>{HeadingText}</h2>
            </div>
            <div className="para">
                <p>{ParaText}</p>
            </div>
        </>
    )
}

const GrideSlider = () => {

    const [filterType, setFilterType] = useState('all');

    const handleFilterChange = (type) => {
        setFilterType(type);
    };

    const filteredProjects = filterType === 'all'
        ? ProjectData
        : ProjectData.filter((project) => project.type === filterType);

    const gridConfig = {
        rows: filterType === 'app' ? 1 : filterType === "design" ? 1 : 2,
    };


    const breakpoints = {
        400: {
            slidesPerView: 1,
            spaceBetween: 0,
            grid: {
                gridConfig
            },
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 0,
            grid: {
                gridConfig
            },
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 0,
            grid: {
                gridConfig
            },
        },
        992: {
            slidesPerView: 4,
            spaceBetween: 0,
            grid: {
                gridConfig
            },
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 0,
            grid: {
                gridConfig
            },
        },
    };


    return (
        <>
            <ul className="portfolioTabs ">
                <li className={filterType === "all" ? "tabsActive" : ""} onClick={() => handleFilterChange('all')}>All</li>
                <li className={filterType === "web" ? "tabsActive" : ""} onClick={() => handleFilterChange('web')}>Web</li>
                <li className={filterType === "app" ? "tabsActive" : ""} onClick={() => handleFilterChange('app')}>App</li>
                <li className={filterType === "design" ? "tabsActive" : ""} onClick={() => handleFilterChange('design')}>Design</li>
            </ul>
            <Swiper
                slidesPerView={1}
                grid={gridConfig}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                modules={[Grid, Pagination]}
                className="mySwiper"
                breakpoints={breakpoints}
            >
                {filteredProjects.map((project) => (
                    <SwiperSlide key={project.id} className=''>
                        <a href={project.projectsLink} target="__blank" className="portfolioProjuctsCardsImg">
                            <img src={project.projuctsCardsImg} alt="" />
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

const FeedBackSlider = () => {

    const breakpoints = {
        992: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
    };

    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                loop={true}
                autoplay={{
                    delay: 3000, // Adjust the delay time (in milliseconds) between slides
                    disableOnInteraction: false, // Allow autoplay to continue even when the user interacts with the slider
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper1"
                breakpoints={breakpoints}
            >
                {FeedBackSliderData.map((item, key) => {
                    return (
                        <SwiperSlide key={key} className=''>
                            <div className="feedBackContent">
                                <div className="feedBackContentPara">
                                    <p>{item.feedBackContentPara}</p>
                                </div>
                                <div className="feedBackContentCustmor">
                                    <div className="feedBackContentCustmorImg">
                                        <img src={item.feedBackContentCustmorImg} alt="" />
                                    </div>
                                    <div className="feedBackContentCustmorContent">
                                        <div className="feedBackContentCustmorContentHeading">
                                            <h5>{item.feedBackContentCustmorContentHeading}</h5>
                                        </div>
                                        <div className="feedBackContentCustmorContentPara">
                                            <span>{item.feedBackContentCustmorContentPara}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper >
        </>
    )
}

const TeamCard = () => {
    return (
        <>
            {TeamCardData.map((item, key) => {
                return (
                    <div className="teamCardItem" key={key}>
                        <div className="teamCardItemImg">
                            <img src={item.teamCardImg} alt="" />
                        </div>
                        <div className="teamCardItemHeading">
                            <h6>{item.teamCardHeading}</h6>
                        </div>
                        <div className="teamCardItemPara">
                            <span>{item.teamCardPara}</span>
                        </div>
                    </div>
                )
            })}
        </>
    )
}


export {
    Loading,
    Banner,
    Heading,
    GrideSlider,
    FeedBackSlider,
    TeamCard,
}