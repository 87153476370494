import React from 'react'
import Error from "../Pages/Error"


const Home = React.lazy(() => import('../Pages/Home'))

const routes = [

    { path: '/', exact: true, name: 'Home', component: Home },

    { path: '/404', exact: true, name: 'Error', component: Error },


]

export default routes