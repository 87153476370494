import { AliBinYounas, ChewCafe, DesignBranding, EbadKhan, Erolack, FeedBackImg, Global, Maintenance, MobileApplication, Resturent, SEOIcon, Scotremovals, Shahriyar, User, WebSite, WebsiteIcon } from "../../img/AllImages"

const servicesData = [
    {
        id: 1,
        servicesImg: WebsiteIcon,
        servicesHeading: "Website Development",
        servicesPara: "Using modern languages and web technologies, we ensure your business presence on the web is at the cutting-edge of web development.",
        servicesDataAttr: "fade-right",
    },
    {
        id: 2,
        servicesImg: MobileApplication,
        servicesHeading: "Mobile Application Development",
        servicesPara: "With the exponential growth of the mobile market, it is imperative for all businesses to ensure they are where the demand is.",
        servicesDataAttr: "fade-left",
    },
    {
        id: 3,
        servicesImg: Maintenance,
        servicesHeading: "Software Development",
        servicesPara: "As we work with our Clients as partner, we are not only make sure that any project goes through our development should pass our QA (Quality Assurance) test, we provide whole hearted support & maintenance.",
        servicesDataAttr: "fade-right",
    },
    {
        id: 4,
        servicesImg: DesignBranding,
        servicesHeading: "Design & Branding",
        servicesPara: "Using modern design principles, our aim is always to keep things simple. We live by the principle 'less is more'. Let us take care of your logo designs and company branding.",
        servicesDataAttr: "fade-left",
    },
    {
        id: 5,
        servicesImg: SEOIcon,
        servicesHeading: "Search Engine Optimization (SEO)",
        servicesPara: "As we work with our Clients as partner, we are not only make sure that any project goes through our development should pass our QA (Quality Assurance) test, we provide whole hearted support & maintenance.",
        servicesDataAttr: "fade-right",
    },
    {
        id: 6,
        servicesImg: WebSite,
        servicesHeading: "Maintenance & Support",
        servicesPara: "As we work with our Clients as partner, we are not only make sure that any project goes through our development should pass our QA (Quality Assurance) test, we provide whole hearted support & maintenance.",
        servicesDataAttr: "fade-left",
    },
]

const ProjectData = [
    {
        id: 1,
        projuctsCardsImg: Scotremovals,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 2,
        projuctsCardsImg: Global,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 3,
        projuctsCardsImg: ChewCafe,
        type: "design",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 4,
        projuctsCardsImg: Erolack,
        type: "web",
        projectsLink: "https://erolackpaints.com",
    },
    {
        id: 5,
        projuctsCardsImg: Resturent,
        type: "app",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 6,
        projuctsCardsImg: Scotremovals,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 7,
        projuctsCardsImg: Global,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 8,
        projuctsCardsImg: ChewCafe,
        type: "design",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 9,
        projuctsCardsImg: Erolack,
        type: "web",
        projectsLink: "https://erolackpaints.com",
    },
    {
        id: 10,
        projuctsCardsImg: Resturent,
        type: "app",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 11,
        projuctsCardsImg: Scotremovals,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 12,
        projuctsCardsImg: Global,
        type: "web",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 13,
        projuctsCardsImg: ChewCafe,
        type: "design",
        projectsLink: "https://scotremovals.com",
    },
    {
        id: 14,
        projuctsCardsImg: Erolack,
        type: "web",
        projectsLink: "https://erolackpaints.com",
    },
]

const FeedBackSliderData = [
    {
        id: 1,
        feedBackContentPara: "“Loved to work with Mark he's such an awesome developer with great attention to details. He also has a great eye for design”",
        feedBackContentCustmorImg: FeedBackImg,
        feedBackContentCustmorContentHeading: "Samantha Bloom",
        feedBackContentCustmorContentPara: "Team Leader - Syncnow",
    },
    {
        id: 2,
        feedBackContentPara: "“Loved to work with Mark he's such an awesome developer with great attention to details. He also has a great eye for design”",
        feedBackContentCustmorImg: FeedBackImg,
        feedBackContentCustmorContentHeading: "Samantha Bloom",
        feedBackContentCustmorContentPara: "Team Leader - Syncnow",
    },
    {
        id: 3,
        feedBackContentPara: "“Loved to work with Mark he's such an awesome developer with great attention to details. He also has a great eye for design”",
        feedBackContentCustmorImg: FeedBackImg,
        feedBackContentCustmorContentHeading: "Samantha Bloom",
        feedBackContentCustmorContentPara: "Team Leader - Syncnow",
    },
    {
        id: 4,
        feedBackContentPara: "“Loved to work with Mark he's such an awesome developer with great attention to details. He also has a great eye for design”",
        feedBackContentCustmorImg: FeedBackImg,
        feedBackContentCustmorContentHeading: "Samantha Bloom",
        feedBackContentCustmorContentPara: "Team Leader - Syncnow",
    },
]

const TeamCardData = [
    {
        id: 1,
        teamCardImg: AliBinYounas,
        teamCardHeading: "Ali Bin Younas",
        teamCardPara: "Founder & CEO",
    },
    {
        id: 2,
        teamCardImg: EbadKhan,
        teamCardHeading: "Ebad-ur-Rehman",
        teamCardPara: "Co-Founder & Partner",
    },
    {
        id: 3,
        teamCardImg: User,
        teamCardHeading: "Asim Riaz",
        teamCardPara: "Most Senior Back End Dev",
    },
    {
        id: 4,
        teamCardImg: Shahriyar,
        teamCardHeading: "Shaheryar Tariq",
        teamCardPara: "Back End / Wordpress Dev",
    },
    {
        id: 5,
        teamCardImg: User,
        teamCardHeading: "Atta Ur Rehman",
        teamCardPara: "Senior Back End Dev",
    },
    {
        id: 6,
        teamCardImg: User,
        teamCardHeading: "Noman Khan",
        teamCardPara: "Senior Back End Dev",
    },
    {
        id: 7,
        teamCardImg: User,
        teamCardHeading: "Sulaiman Ehsan",
        teamCardPara: "Back End Dev",
    },
    {
        id: 8,
        teamCardImg: User,
        teamCardHeading: "Nafees Ahmad",
        teamCardPara: "Back End Dev",
    },
    {
        id: 9,
        teamCardImg: User,
        teamCardHeading: "Danish Ayounb",
        teamCardPara: "Senior Front End Dev",
    },
    {
        id: 10,
        teamCardImg: User,
        teamCardHeading: "Ahmad Zubairy",
        teamCardPara: "Front End Dev",
    },
    {
        id: 11,
        teamCardImg: User,
        teamCardHeading: "Qais Rajpoot",
        teamCardPara: "Laravel Dev",
    },
    {
        id: 12,
        teamCardImg: User,
        teamCardHeading: "Asad Ansari",
        teamCardPara: "React Dev",
    },
]

export {
    servicesData,
    ProjectData,
    FeedBackSliderData,
    TeamCardData,
}