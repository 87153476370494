import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from './routes/Layout'
import AOS from 'aos';

const App = () => {

  useEffect(() => {
    AOS.init(); // Initialize AOS once the component mounts
    AOS.refresh();
  }, []);

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Layout />
    </>
  )
}

export default App