import React from 'react'
import ErrorSec from './Error/ErrorSec'

const Error = () => {
    return (
        <>
            <ErrorSec />
        </>
    )
}

export default Error