import React from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../../img/AllImages'

const ErrorSec = () => {
  return (
    <div className="errorPage">
      <div className="errorPageLogo">
        <img src={Logo} alt="" />
      </div>
      <div className="errorPageHeading">
        <h2>404</h2>
      </div>
      <div className="errorPageSubHeading">
        <h4>Page Not Found</h4>
      </div>
      <div className="errorPageLink">
        <Link to="/">Back To Home</Link>
      </div>
    </div>
  )
}

export default ErrorSec